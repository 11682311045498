

import React from 'react'
import Navbar  from "../components/Navbar"
import Footer from '../components/Footer'
// import Expertise_Content from '../components/Expertise_Content';
// import Expertise_Content from '../components/Expertise_Content';
import Slider from '../components/Slider';



const Expertise = () => {
  

  return (
    <div>
    <Navbar/>
    <Slider/>
    <Footer/>
    </div>
  );
};

export default Expertise;




  
