import React from 'react';
import AdminNavbar2 from "../components/AdminNavbar2"
import Footer from '../components/Footer'
import Dash from '../components/Dash';

const DashRoute = () => {
    return (
        <div>
            
            <AdminNavbar2/>
            <Dash/>
            <Footer/>
        </div>
    );
}

export default DashRoute;





